import { gql } from 'GQL';

export const SupplierFragment = gql(`
  fragment SupplierFragment on Supplier {
    id
    createdAt
    updatedAt
    countryId
    vat
    name
  }
`);
