/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n  fragment AttributeFragment on Attribute {\n    id\n    createdAt\n    updatedAt\n    synchedAt\n    slug\n    name\n    type\n    originalId\n  }\n": types.AttributeFragmentFragmentDoc,
    "\n  query GetAttributes($input: GraphQLListInput!) {\n    attributes: attributes(input: $input) {\n      ...AttributeFragment\n    }\n  }\n": types.GetAttributesDocument,
    "\n  query SearchAttributes($input: GraphQLSearchInput!) {\n    attributes: searchAttributes(input: $input) {\n      ...AttributeFragment\n    }\n  }\n": types.SearchAttributesDocument,
    "\n  fragment AttributeValueFragment on AttributeValue {\n    createdAt\n    updatedAt\n    synchedAt\n    slug\n    value\n    imageURLs\n    originalId\n    attribute {\n      ...AttributeFragment\n    }\n  }\n": types.AttributeValueFragmentFragmentDoc,
    "\n  query GetAttributeValues($input: GraphQLListInput!) {\n    attributeValues: attributeValues(input: $input) {\n      ...AttributeValueFragment\n    }\n  }\n": types.GetAttributeValuesDocument,
    "\n  query SearchAttributeValues($input: GraphQLSearchInput!) {\n    attributeValues: searchAttributeValues(input: $input) {\n      ...AttributeValueFragment\n    }\n  }\n": types.SearchAttributeValuesDocument,
    "\n  fragment BillFragment on Bill {\n    id\n    createdAt\n    updatedAt\n    date\n    total\n    documentType\n    documentNumber\n    supplier {\n      ...SupplierFragment\n    }\n  }\n": types.BillFragmentFragmentDoc,
    "\n  query GetBillsQuery($input: GraphQLListInput!) {\n    bills: bills(input: $input) {\n      ...BillFragment\n    }\n  }\n": types.GetBillsQueryDocument,
    "\n  query SearchBills($input: GraphQLSearchInput!) {\n    bills: searchBills(input: $input) {\n      ...BillFragment\n    }\n  }\n": types.SearchBillsDocument,
    "\n  fragment BillItemFragment on BillItem {\n    id\n    createdAt\n    updatedAt\n    itemCode\n    description\n    quantity\n    unitPrice\n    unitOfMeasure\n    discount\n    increase\n    total\n    vatRate\n    bill {\n      ...BillFragment\n    }\n  }\n": types.BillItemFragmentFragmentDoc,
    "\n  query GetBillItemsQuery($input: GraphQLListInput!) {\n    billItems: billItems(input: $input) {\n      ...BillItemFragment\n    }\n  }\n": types.GetBillItemsQueryDocument,
    "\n  query SearchBillItems($input: GraphQLSearchInput!) {\n    billItems: searchBillItems(input: $input) {\n      ...BillItemFragment\n    }\n  }\n": types.SearchBillItemsDocument,
    "\n  fragment SingleCategoryFragment on Category {\n    id\n    createdAt\n    updatedAt\n    synchedAt\n    name\n    slug\n    originalId\n  }\n": types.SingleCategoryFragmentFragmentDoc,
    "\n  fragment CategoryFragment on Category {\n    ...SingleCategoryFragment\n    parent {\n      ...SingleCategoryFragment\n      parent {\n        ...SingleCategoryFragment\n        parent {\n          ...SingleCategoryFragment\n        }\n      }\n    }\n  }\n": types.CategoryFragmentFragmentDoc,
    "\n  query GetCategories($input: GraphQLListInput!) {\n    categories: categories(input: $input) {\n      ...CategoryFragment\n    }\n  }\n": types.GetCategoriesDocument,
    "\n  query SearchCategories($input: GraphQLSearchInput!) {\n    categories: searchCategories(input: $input) {\n      ...CategoryFragment\n    }\n  }\n": types.SearchCategoriesDocument,
    "\n  mutation CreateInventory($input: InventoryCreateInput!) {\n    inventory: createInventory(input: $input) {\n      ...InventoryFragment\n    }\n  }\n": types.CreateInventoryDocument,
    "\n  mutation CreateTask($barcode: String!, $count: Float) {\n    task: createTask(barcode: $barcode, count: $count) {\n      ...TaskFragment\n    }\n  }\n": types.CreateTaskDocument,
    "\n  mutation DeleteBill($id: Float!) {\n    isDeleted: deleteBill(id: $id)\n  }\n": types.DeleteBillDocument,
    "\n  mutation DeleteInventory($id: Float!) {\n    isDeleted: deleteInventory(id: $id)\n  }\n": types.DeleteInventoryDocument,
    "\n  mutation DeleteTask($id: Float!) {\n    isDeleted: deleteTask(id: $id)\n  }\n": types.DeleteTaskDocument,
    "\n  fragment InventoryFragment on Inventory {\n    id\n    barcode\n    count\n    createdAt\n    updatedAt\n    product {\n      title\n      type\n      imageURLs\n      variations {\n        sku\n        attributeValues {\n          attribute {\n            name\n            slug\n          }\n          value\n        }\n      }\n    }\n  }\n": types.InventoryFragmentFragmentDoc,
    "\n  query GetInventoriesQuery($input: GraphQLListInput!) {\n    inventories: inventories(input: $input) {\n      ...InventoryFragment\n    }\n  }\n": types.GetInventoriesQueryDocument,
    "\n  query SearchInventories($input: GraphQLSearchInput!) {\n    inventories: searchInventories(input: $input) {\n      ...InventoryFragment\n    }\n  }\n": types.SearchInventoriesDocument,
    "\n  fragment ProductFragment on Product {\n    id\n    createdAt\n    updatedAt\n    synchedAt\n    description\n    inStock\n    originalId\n    price\n    sku\n    status\n    stockCount\n    title\n    type\n    imageURLs\n    attributeValues {\n      ...AttributeValueFragment\n    }\n    categories {\n      ...CategoryFragment\n    }\n    variations {\n      ...ProductVariationFragment\n    }\n    tags {\n      ...TagFragment\n    }\n  }\n": types.ProductFragmentFragmentDoc,
    "\n  query GetProducts($input: GraphQLListInput!) {\n    products: products(input: $input) {\n      ...ProductFragment\n    }\n  }\n": types.GetProductsDocument,
    "\n  query SearchProducts($input: GraphQLSearchInput!) {\n    products: searchProducts(input: $input) {\n      ...ProductFragment\n    }\n  }\n": types.SearchProductsDocument,
    "\n  fragment ProductVariationFragment on ProductVariation {\n    id\n    createdAt\n    updatedAt\n    synchedAt\n    description\n    inStock\n    originalId\n    price\n    sku\n    stockCount\n    title\n    imageURLs\n    attributeValues {\n      ...AttributeValueFragment\n    }\n  }\n": types.ProductVariationFragmentFragmentDoc,
    "\n  fragment ProductVariationFragmentWithParent on ProductVariation {\n    id\n    createdAt\n    updatedAt\n    synchedAt\n    description\n    inStock\n    originalId\n    price\n    sku\n    stockCount\n    title\n    imageURLs\n    attributeValues {\n      ...AttributeValueFragment\n    }\n    parent {\n      ...ProductFragment\n    }\n  }\n": types.ProductVariationFragmentWithParentFragmentDoc,
    "\n  query GetProductVariations($input: GraphQLListInput!) {\n    productsVariations: productVariations(input: $input) {\n      ...ProductVariationFragmentWithParent\n    }\n  }\n": types.GetProductVariationsDocument,
    "\n  query SearchProductVariations($input: GraphQLSearchInput!) {\n    productVariations: searchProductVariations(input: $input) {\n      ...ProductVariationFragmentWithParent\n    }\n  }\n": types.SearchProductVariationsDocument,
    "\n  fragment SupplierFragment on Supplier {\n    id\n    createdAt\n    updatedAt\n    countryId\n    vat\n    name\n  }\n": types.SupplierFragmentFragmentDoc,
    "\n  fragment TagFragment on Tag {\n    createdAt\n    updatedAt\n    synchedAt\n    id\n    name\n    slug\n    originalId\n  }\n": types.TagFragmentFragmentDoc,
    "\n  query GetTagsQuery($input: GraphQLListInput!) {\n    tags: tags(input: $input) {\n      ...TagFragment\n    }\n  }\n": types.GetTagsQueryDocument,
    "\n  query SearchTags($input: GraphQLSearchInput!) {\n    tags: searchTags(input: $input) {\n      ...TagFragment\n    }\n  }\n": types.SearchTagsDocument,
    "\n  fragment TaskFragment on Task {\n    id\n    barcode\n    count\n    createdAt\n    updatedAt\n    status\n  }\n": types.TaskFragmentFragmentDoc,
    "\n  query GetTasksQuery($input: GraphQLListInput!) {\n    tasks: tasks(input: $input) {\n      ...TaskFragment\n    }\n  }\n": types.GetTasksQueryDocument,
    "\n  query SearchTasks($input: GraphQLSearchInput!) {\n    tasks: searchTasks(input: $input) {\n      ...TaskFragment\n    }\n  }\n": types.SearchTasksDocument,
    "\n  fragment TaskResultFragment on TaskResult {\n    module\n    data {\n      name\n      category\n      variants {\n        attributes\n        sku\n        price\n        images\n        barcode\n        quantity\n      }\n      sku\n      description\n      brand\n    }\n  }\n": types.TaskResultFragmentFragmentDoc,
    "\n  query GetTaskResultsQuery($id: Float!) {\n    taskResults: taskResults(id: $id) {\n      ...TaskResultFragment\n    }\n  }\n": types.GetTaskResultsQueryDocument,
    "\n  mutation UpdateInventory($id: Float!, $input: InventoryUpdateInput!) {\n    inventory: updateInventory(id: $id, input: $input) {\n      ...InventoryFragment\n    }\n  }\n": types.UpdateInventoryDocument,
    "\n  mutation UpdateProduct($input: ProductUpdateInput!, $id: Float!) {\n    product: updateProduct(input: $input, id: $id) {\n      ...ProductFragment\n    }\n  }\n": types.UpdateProductDocument,
    "\n  mutation UpdateProductVariation($input: ProductVariationUpdateInput!, $id: Float!) {\n    product: updateProductVariation(input: $input, id: $id) {\n      ...ProductVariationFragmentWithParent\n    }\n  }\n": types.UpdateProductVariationDocument,
    "\n  mutation UpsertInventory($input: InventoryUpsertInput!) {\n    inventory: upsertInventory(input: $input) {\n      ...InventoryFragment\n    }\n  }\n": types.UpsertInventoryDocument,
    "\n  mutation UpsertInventories($input: [InventoryUpsertInput!]!) {\n    inventories: upsertInventories(input: $input) {\n      ...InventoryFragment\n    }\n  }\n": types.UpsertInventoriesDocument,
    "\n  fragment UserFragment on User {\n    id\n    name\n  }\n": types.UserFragmentFragmentDoc,
    "\n  query GetMe {\n    me {\n      ...UserFragment\n    }\n  }\n": types.GetMeDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AttributeFragment on Attribute {\n    id\n    createdAt\n    updatedAt\n    synchedAt\n    slug\n    name\n    type\n    originalId\n  }\n"): (typeof documents)["\n  fragment AttributeFragment on Attribute {\n    id\n    createdAt\n    updatedAt\n    synchedAt\n    slug\n    name\n    type\n    originalId\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetAttributes($input: GraphQLListInput!) {\n    attributes: attributes(input: $input) {\n      ...AttributeFragment\n    }\n  }\n"): (typeof documents)["\n  query GetAttributes($input: GraphQLListInput!) {\n    attributes: attributes(input: $input) {\n      ...AttributeFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SearchAttributes($input: GraphQLSearchInput!) {\n    attributes: searchAttributes(input: $input) {\n      ...AttributeFragment\n    }\n  }\n"): (typeof documents)["\n  query SearchAttributes($input: GraphQLSearchInput!) {\n    attributes: searchAttributes(input: $input) {\n      ...AttributeFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AttributeValueFragment on AttributeValue {\n    createdAt\n    updatedAt\n    synchedAt\n    slug\n    value\n    imageURLs\n    originalId\n    attribute {\n      ...AttributeFragment\n    }\n  }\n"): (typeof documents)["\n  fragment AttributeValueFragment on AttributeValue {\n    createdAt\n    updatedAt\n    synchedAt\n    slug\n    value\n    imageURLs\n    originalId\n    attribute {\n      ...AttributeFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetAttributeValues($input: GraphQLListInput!) {\n    attributeValues: attributeValues(input: $input) {\n      ...AttributeValueFragment\n    }\n  }\n"): (typeof documents)["\n  query GetAttributeValues($input: GraphQLListInput!) {\n    attributeValues: attributeValues(input: $input) {\n      ...AttributeValueFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SearchAttributeValues($input: GraphQLSearchInput!) {\n    attributeValues: searchAttributeValues(input: $input) {\n      ...AttributeValueFragment\n    }\n  }\n"): (typeof documents)["\n  query SearchAttributeValues($input: GraphQLSearchInput!) {\n    attributeValues: searchAttributeValues(input: $input) {\n      ...AttributeValueFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment BillFragment on Bill {\n    id\n    createdAt\n    updatedAt\n    date\n    total\n    documentType\n    documentNumber\n    supplier {\n      ...SupplierFragment\n    }\n  }\n"): (typeof documents)["\n  fragment BillFragment on Bill {\n    id\n    createdAt\n    updatedAt\n    date\n    total\n    documentType\n    documentNumber\n    supplier {\n      ...SupplierFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetBillsQuery($input: GraphQLListInput!) {\n    bills: bills(input: $input) {\n      ...BillFragment\n    }\n  }\n"): (typeof documents)["\n  query GetBillsQuery($input: GraphQLListInput!) {\n    bills: bills(input: $input) {\n      ...BillFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SearchBills($input: GraphQLSearchInput!) {\n    bills: searchBills(input: $input) {\n      ...BillFragment\n    }\n  }\n"): (typeof documents)["\n  query SearchBills($input: GraphQLSearchInput!) {\n    bills: searchBills(input: $input) {\n      ...BillFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment BillItemFragment on BillItem {\n    id\n    createdAt\n    updatedAt\n    itemCode\n    description\n    quantity\n    unitPrice\n    unitOfMeasure\n    discount\n    increase\n    total\n    vatRate\n    bill {\n      ...BillFragment\n    }\n  }\n"): (typeof documents)["\n  fragment BillItemFragment on BillItem {\n    id\n    createdAt\n    updatedAt\n    itemCode\n    description\n    quantity\n    unitPrice\n    unitOfMeasure\n    discount\n    increase\n    total\n    vatRate\n    bill {\n      ...BillFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetBillItemsQuery($input: GraphQLListInput!) {\n    billItems: billItems(input: $input) {\n      ...BillItemFragment\n    }\n  }\n"): (typeof documents)["\n  query GetBillItemsQuery($input: GraphQLListInput!) {\n    billItems: billItems(input: $input) {\n      ...BillItemFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SearchBillItems($input: GraphQLSearchInput!) {\n    billItems: searchBillItems(input: $input) {\n      ...BillItemFragment\n    }\n  }\n"): (typeof documents)["\n  query SearchBillItems($input: GraphQLSearchInput!) {\n    billItems: searchBillItems(input: $input) {\n      ...BillItemFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment SingleCategoryFragment on Category {\n    id\n    createdAt\n    updatedAt\n    synchedAt\n    name\n    slug\n    originalId\n  }\n"): (typeof documents)["\n  fragment SingleCategoryFragment on Category {\n    id\n    createdAt\n    updatedAt\n    synchedAt\n    name\n    slug\n    originalId\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CategoryFragment on Category {\n    ...SingleCategoryFragment\n    parent {\n      ...SingleCategoryFragment\n      parent {\n        ...SingleCategoryFragment\n        parent {\n          ...SingleCategoryFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment CategoryFragment on Category {\n    ...SingleCategoryFragment\n    parent {\n      ...SingleCategoryFragment\n      parent {\n        ...SingleCategoryFragment\n        parent {\n          ...SingleCategoryFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCategories($input: GraphQLListInput!) {\n    categories: categories(input: $input) {\n      ...CategoryFragment\n    }\n  }\n"): (typeof documents)["\n  query GetCategories($input: GraphQLListInput!) {\n    categories: categories(input: $input) {\n      ...CategoryFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SearchCategories($input: GraphQLSearchInput!) {\n    categories: searchCategories(input: $input) {\n      ...CategoryFragment\n    }\n  }\n"): (typeof documents)["\n  query SearchCategories($input: GraphQLSearchInput!) {\n    categories: searchCategories(input: $input) {\n      ...CategoryFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateInventory($input: InventoryCreateInput!) {\n    inventory: createInventory(input: $input) {\n      ...InventoryFragment\n    }\n  }\n"): (typeof documents)["\n  mutation CreateInventory($input: InventoryCreateInput!) {\n    inventory: createInventory(input: $input) {\n      ...InventoryFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateTask($barcode: String!, $count: Float) {\n    task: createTask(barcode: $barcode, count: $count) {\n      ...TaskFragment\n    }\n  }\n"): (typeof documents)["\n  mutation CreateTask($barcode: String!, $count: Float) {\n    task: createTask(barcode: $barcode, count: $count) {\n      ...TaskFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteBill($id: Float!) {\n    isDeleted: deleteBill(id: $id)\n  }\n"): (typeof documents)["\n  mutation DeleteBill($id: Float!) {\n    isDeleted: deleteBill(id: $id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteInventory($id: Float!) {\n    isDeleted: deleteInventory(id: $id)\n  }\n"): (typeof documents)["\n  mutation DeleteInventory($id: Float!) {\n    isDeleted: deleteInventory(id: $id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteTask($id: Float!) {\n    isDeleted: deleteTask(id: $id)\n  }\n"): (typeof documents)["\n  mutation DeleteTask($id: Float!) {\n    isDeleted: deleteTask(id: $id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment InventoryFragment on Inventory {\n    id\n    barcode\n    count\n    createdAt\n    updatedAt\n    product {\n      title\n      type\n      imageURLs\n      variations {\n        sku\n        attributeValues {\n          attribute {\n            name\n            slug\n          }\n          value\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment InventoryFragment on Inventory {\n    id\n    barcode\n    count\n    createdAt\n    updatedAt\n    product {\n      title\n      type\n      imageURLs\n      variations {\n        sku\n        attributeValues {\n          attribute {\n            name\n            slug\n          }\n          value\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetInventoriesQuery($input: GraphQLListInput!) {\n    inventories: inventories(input: $input) {\n      ...InventoryFragment\n    }\n  }\n"): (typeof documents)["\n  query GetInventoriesQuery($input: GraphQLListInput!) {\n    inventories: inventories(input: $input) {\n      ...InventoryFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SearchInventories($input: GraphQLSearchInput!) {\n    inventories: searchInventories(input: $input) {\n      ...InventoryFragment\n    }\n  }\n"): (typeof documents)["\n  query SearchInventories($input: GraphQLSearchInput!) {\n    inventories: searchInventories(input: $input) {\n      ...InventoryFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ProductFragment on Product {\n    id\n    createdAt\n    updatedAt\n    synchedAt\n    description\n    inStock\n    originalId\n    price\n    sku\n    status\n    stockCount\n    title\n    type\n    imageURLs\n    attributeValues {\n      ...AttributeValueFragment\n    }\n    categories {\n      ...CategoryFragment\n    }\n    variations {\n      ...ProductVariationFragment\n    }\n    tags {\n      ...TagFragment\n    }\n  }\n"): (typeof documents)["\n  fragment ProductFragment on Product {\n    id\n    createdAt\n    updatedAt\n    synchedAt\n    description\n    inStock\n    originalId\n    price\n    sku\n    status\n    stockCount\n    title\n    type\n    imageURLs\n    attributeValues {\n      ...AttributeValueFragment\n    }\n    categories {\n      ...CategoryFragment\n    }\n    variations {\n      ...ProductVariationFragment\n    }\n    tags {\n      ...TagFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetProducts($input: GraphQLListInput!) {\n    products: products(input: $input) {\n      ...ProductFragment\n    }\n  }\n"): (typeof documents)["\n  query GetProducts($input: GraphQLListInput!) {\n    products: products(input: $input) {\n      ...ProductFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SearchProducts($input: GraphQLSearchInput!) {\n    products: searchProducts(input: $input) {\n      ...ProductFragment\n    }\n  }\n"): (typeof documents)["\n  query SearchProducts($input: GraphQLSearchInput!) {\n    products: searchProducts(input: $input) {\n      ...ProductFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ProductVariationFragment on ProductVariation {\n    id\n    createdAt\n    updatedAt\n    synchedAt\n    description\n    inStock\n    originalId\n    price\n    sku\n    stockCount\n    title\n    imageURLs\n    attributeValues {\n      ...AttributeValueFragment\n    }\n  }\n"): (typeof documents)["\n  fragment ProductVariationFragment on ProductVariation {\n    id\n    createdAt\n    updatedAt\n    synchedAt\n    description\n    inStock\n    originalId\n    price\n    sku\n    stockCount\n    title\n    imageURLs\n    attributeValues {\n      ...AttributeValueFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ProductVariationFragmentWithParent on ProductVariation {\n    id\n    createdAt\n    updatedAt\n    synchedAt\n    description\n    inStock\n    originalId\n    price\n    sku\n    stockCount\n    title\n    imageURLs\n    attributeValues {\n      ...AttributeValueFragment\n    }\n    parent {\n      ...ProductFragment\n    }\n  }\n"): (typeof documents)["\n  fragment ProductVariationFragmentWithParent on ProductVariation {\n    id\n    createdAt\n    updatedAt\n    synchedAt\n    description\n    inStock\n    originalId\n    price\n    sku\n    stockCount\n    title\n    imageURLs\n    attributeValues {\n      ...AttributeValueFragment\n    }\n    parent {\n      ...ProductFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetProductVariations($input: GraphQLListInput!) {\n    productsVariations: productVariations(input: $input) {\n      ...ProductVariationFragmentWithParent\n    }\n  }\n"): (typeof documents)["\n  query GetProductVariations($input: GraphQLListInput!) {\n    productsVariations: productVariations(input: $input) {\n      ...ProductVariationFragmentWithParent\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SearchProductVariations($input: GraphQLSearchInput!) {\n    productVariations: searchProductVariations(input: $input) {\n      ...ProductVariationFragmentWithParent\n    }\n  }\n"): (typeof documents)["\n  query SearchProductVariations($input: GraphQLSearchInput!) {\n    productVariations: searchProductVariations(input: $input) {\n      ...ProductVariationFragmentWithParent\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment SupplierFragment on Supplier {\n    id\n    createdAt\n    updatedAt\n    countryId\n    vat\n    name\n  }\n"): (typeof documents)["\n  fragment SupplierFragment on Supplier {\n    id\n    createdAt\n    updatedAt\n    countryId\n    vat\n    name\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment TagFragment on Tag {\n    createdAt\n    updatedAt\n    synchedAt\n    id\n    name\n    slug\n    originalId\n  }\n"): (typeof documents)["\n  fragment TagFragment on Tag {\n    createdAt\n    updatedAt\n    synchedAt\n    id\n    name\n    slug\n    originalId\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetTagsQuery($input: GraphQLListInput!) {\n    tags: tags(input: $input) {\n      ...TagFragment\n    }\n  }\n"): (typeof documents)["\n  query GetTagsQuery($input: GraphQLListInput!) {\n    tags: tags(input: $input) {\n      ...TagFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SearchTags($input: GraphQLSearchInput!) {\n    tags: searchTags(input: $input) {\n      ...TagFragment\n    }\n  }\n"): (typeof documents)["\n  query SearchTags($input: GraphQLSearchInput!) {\n    tags: searchTags(input: $input) {\n      ...TagFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment TaskFragment on Task {\n    id\n    barcode\n    count\n    createdAt\n    updatedAt\n    status\n  }\n"): (typeof documents)["\n  fragment TaskFragment on Task {\n    id\n    barcode\n    count\n    createdAt\n    updatedAt\n    status\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetTasksQuery($input: GraphQLListInput!) {\n    tasks: tasks(input: $input) {\n      ...TaskFragment\n    }\n  }\n"): (typeof documents)["\n  query GetTasksQuery($input: GraphQLListInput!) {\n    tasks: tasks(input: $input) {\n      ...TaskFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SearchTasks($input: GraphQLSearchInput!) {\n    tasks: searchTasks(input: $input) {\n      ...TaskFragment\n    }\n  }\n"): (typeof documents)["\n  query SearchTasks($input: GraphQLSearchInput!) {\n    tasks: searchTasks(input: $input) {\n      ...TaskFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment TaskResultFragment on TaskResult {\n    module\n    data {\n      name\n      category\n      variants {\n        attributes\n        sku\n        price\n        images\n        barcode\n        quantity\n      }\n      sku\n      description\n      brand\n    }\n  }\n"): (typeof documents)["\n  fragment TaskResultFragment on TaskResult {\n    module\n    data {\n      name\n      category\n      variants {\n        attributes\n        sku\n        price\n        images\n        barcode\n        quantity\n      }\n      sku\n      description\n      brand\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetTaskResultsQuery($id: Float!) {\n    taskResults: taskResults(id: $id) {\n      ...TaskResultFragment\n    }\n  }\n"): (typeof documents)["\n  query GetTaskResultsQuery($id: Float!) {\n    taskResults: taskResults(id: $id) {\n      ...TaskResultFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateInventory($id: Float!, $input: InventoryUpdateInput!) {\n    inventory: updateInventory(id: $id, input: $input) {\n      ...InventoryFragment\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateInventory($id: Float!, $input: InventoryUpdateInput!) {\n    inventory: updateInventory(id: $id, input: $input) {\n      ...InventoryFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateProduct($input: ProductUpdateInput!, $id: Float!) {\n    product: updateProduct(input: $input, id: $id) {\n      ...ProductFragment\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateProduct($input: ProductUpdateInput!, $id: Float!) {\n    product: updateProduct(input: $input, id: $id) {\n      ...ProductFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateProductVariation($input: ProductVariationUpdateInput!, $id: Float!) {\n    product: updateProductVariation(input: $input, id: $id) {\n      ...ProductVariationFragmentWithParent\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateProductVariation($input: ProductVariationUpdateInput!, $id: Float!) {\n    product: updateProductVariation(input: $input, id: $id) {\n      ...ProductVariationFragmentWithParent\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpsertInventory($input: InventoryUpsertInput!) {\n    inventory: upsertInventory(input: $input) {\n      ...InventoryFragment\n    }\n  }\n"): (typeof documents)["\n  mutation UpsertInventory($input: InventoryUpsertInput!) {\n    inventory: upsertInventory(input: $input) {\n      ...InventoryFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpsertInventories($input: [InventoryUpsertInput!]!) {\n    inventories: upsertInventories(input: $input) {\n      ...InventoryFragment\n    }\n  }\n"): (typeof documents)["\n  mutation UpsertInventories($input: [InventoryUpsertInput!]!) {\n    inventories: upsertInventories(input: $input) {\n      ...InventoryFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment UserFragment on User {\n    id\n    name\n  }\n"): (typeof documents)["\n  fragment UserFragment on User {\n    id\n    name\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetMe {\n    me {\n      ...UserFragment\n    }\n  }\n"): (typeof documents)["\n  query GetMe {\n    me {\n      ...UserFragment\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;