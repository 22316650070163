import { gql } from 'GQL';

export const BillFragment = gql(`
  fragment BillFragment on Bill {
    id
    createdAt
    updatedAt
    date
    total
    documentType
    documentNumber
    supplier {
      ...SupplierFragment
    }
  }
`);
