import { gql } from 'GQL';

export const BillItemFragment = gql(`
  fragment BillItemFragment on BillItem {
    id
    createdAt
    updatedAt
    itemCode
    description
    quantity
    unitPrice
    unitOfMeasure
    discount
    increase
    total
    vatRate
    bill {
      ...BillFragment
    }
  }
`);
