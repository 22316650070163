import { gql } from 'GQL';

export const InventoryFragment = gql(`
  fragment InventoryFragment on Inventory {
    id
    barcode
    count
    createdAt
    updatedAt
    product {
      title
      type
      imageURLs
      variations {
        sku
        attributeValues {
          attribute {
            name
            slug
          }
          value
        }
      }
    }
  }
`);
