import {
  BuildingLibraryIcon,
  ClockIcon,
  DocumentCheckIcon,
  CurrencyEuroIcon,
  TrashIcon,
  RectangleGroupIcon,
} from '@heroicons/react/24/outline';
import { IconSpec } from 'Components/IconSpec';
import style from './bill.module.scss';
import { GQLBill } from 'Types';
import ToastPrompt from 'Components/ToastPrompt';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { DeleteBillMutation } from 'GraphqlQueries';
import { useNavigate } from 'react-router-dom';

export default function Bill({ bill, refetch }: { bill: GQLBill; refetch: () => void }) {
  const navigate = useNavigate();
  const [deleteBill] = useMutation(DeleteBillMutation, {
    variables: {
      id: parseInt(bill.id),
    },
    onCompleted: () => {
      refetch();
    },
  });

  const deleteRowPrompt = () => {
    const toastId = toast(
      <ToastPrompt
        onAbort={() => {
          toast.dismiss(toastId);
        }}
        onApply={() => {
          deleteBill().then(() => {
            toast.success('Elemento eliminato con successo');
            toast.dismiss(toastId);
          });
        }}
        actionLabel="Elimina"
        textLabel={`Sicuro di voler eliminare definitivamente l'elemento?`}
      />,
      {
        style: {
          backgroundColor: 'white',
        },
      },
    );
  };

  return (
    <div className={style.bill}>
      <IconSpec icon={<BuildingLibraryIcon width={20} />} label="Fornitore" content={bill.supplier.name} />
      <IconSpec
        icon={<ClockIcon width={20} />}
        label="Data emissione"
        content={new Date(bill.date).toLocaleDateString('it-IT')}
      />
      <IconSpec icon={<DocumentCheckIcon width={20} />} label="Numero documento" content={bill.documentNumber} />
      <IconSpec icon={<CurrencyEuroIcon width={20} />} label="Totale" content={`${bill.total.toString()} €`} />
      <div className={style.billButtons}>
        <IconSpec
          icon={<RectangleGroupIcon width={20} color={style.green} />}
          label="Visualizza"
          labelStyle={{ color: style.green }}
          onClick={() => navigate(`/bills/items?bill=${bill.id}`)}
        />
        <IconSpec
          icon={<TrashIcon width={20} color={style.red} />}
          label="Elimina"
          labelStyle={{ color: style.red }}
          onClick={deleteRowPrompt}
        />
      </div>
    </div>
  );
}
