import { Route, Routes, Navigate } from 'react-router-dom';
import Bills from './Bills/bills';
import BillItems from './BillItems/billItems';

export function BillsContent() {
  return (
    <Routes>
      <Route path="/bills" element={<Bills />} />
      <Route path="/items" element={<BillItems />} />
      <Route path="*" element={<Navigate to="/bills/bills" />} />
    </Routes>
  );
}
