import { GetTasksResultQuery } from 'GraphqlQueries';
import style from './taskResult.module.scss';
import { useQuery } from '@apollo/client';
import Loader from 'Components/Loader';
import { GQLTaskResult } from 'Types';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

export default function TaskResult({ id }: { id: string }): JSX.Element {
  const { data, loading, error } = useQuery(GetTasksResultQuery, {
    variables: { id: parseInt(id) },
  });

  useEffect(() => {
    if (error) {
      toast.error('Errore durante il caricamento dei risultati del task: ' + error.message);
    }
  }, [error]);

  return (
    <div className={style.taskResult}>
      {loading && <Loader />}
      <div className={style.taskResultItems}>
        {data?.taskResults.map((taskResult: GQLTaskResult, i) => {
          return (
            <div key={i} className={style.taskResultItemsItem}>
              <pre>{JSON.stringify(taskResult, null, 2)}</pre>
            </div>
          );
        })}
      </div>
    </div>
  );
}
