import style from './scanner.module.scss';
import { ContentHeader } from 'Components/ContentHeader';

export default function Scanner() {
  return (
    <div className={style.content}>
      <ContentHeader title="Impostazioni barcode scanner" isSearchVisible={false}>
        {/* <ContentHeaderGroup label="Barcode Scanner">
          <ContentHeaderButton selected={ports && ports.length > 0} onClick={() => requestUserAction()}>
            {ports && ports.length > 0 ? 'Connesso' : 'Connetti'}
          </ContentHeaderButton>
        </ContentHeaderGroup> */}
      </ContentHeader>
      <div className={style.contentBody}>
        {/* lista impostazioni per il barcode scanner */}
        <div className={style.contentBodySetting}>
          <div className={style.contentBodySettingLabel}>Barcode Scanner</div>
          <div className={style.contentBodySettingValue}></div>
        </div>
      </div>
    </div>
  );
}
