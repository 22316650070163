import { gql } from 'GQL';

export const UpsertInventoryMutation = gql(`
  mutation UpsertInventory($input: InventoryUpsertInput!) {
    inventory: upsertInventory(input: $input) {
      ...InventoryFragment
    }
  }
`);

export const UpsertInventoriesMutation = gql(`
  mutation UpsertInventories($input: [InventoryUpsertInput!]!) {
    inventories: upsertInventories(input: $input) {
      ...InventoryFragment
    }
  }
`);
