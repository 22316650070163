import {
  QrCodeIcon,
  TrashIcon,
  CubeTransparentIcon,
  PlusCircleIcon,
  MinusCircleIcon,
  DocumentDuplicateIcon,
} from '@heroicons/react/24/outline';
import { IconSpec } from 'Components/IconSpec';
import style from './inventory.module.scss';
import { useMutation } from '@apollo/client';
import { CreateTaskMutation } from 'GraphqlQueries';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { GQLInventory } from 'Types';
import { ProductType } from 'GQL/graphql';
import ToastPrompt from 'Components/ToastPrompt';

export default function InventoryRow({
  inventory: { barcode, count, product },
  deleteRowPrompt,
  deleteRow,
  editCount,
}: {
  inventory: GQLInventory;
  deleteRowPrompt: () => void;
  deleteRow: () => void;
  editCount: (count: number) => void;
}) {
  const [variation, setVariation] = useState(product?.variations?.[0]);

  const [createTask] = useMutation(CreateTaskMutation, {
    variables: {
      barcode,
      count,
    },
  });

  useEffect(() => {
    if (product && product.type === ProductType.Variable && product.variations) {
      for (const v of product.variations) {
        if (v.sku === barcode) {
          setVariation(v);
          break;
        }
      }
    }
  }, [barcode, product]);

  const addTask = async () => {
    const toastId = toast(
      <ToastPrompt
        actionLabel="Crea"
        textLabel={`Sicuro di voler creare un task per ${barcode}?`}
        onApply={function (): void {
          createTask().then(() => {
            deleteRow();
          });
          toast.dismiss(toastId);
        }}
        onAbort={function (): void {
          toast.dismiss(toastId);
        }}
      />,
      {
        style: {
          backgroundColor: 'white',
        },
      },
    );
  };

  return (
    <div className={style.inventoryRow}>
      <div className={style.inventoryRowProduct}>
        {!!!product && <span className={style.inventoryRowProductNotFound}>Prodotto non trovato</span>}
        {product && <img src={product.imageURLs[0]} alt={product.title} className={style.inventoryRowProductImage} />}
        <div className={style.inventoryRowProductContent}>
          {product && <div className={style.inventoryRowProductContentTitle}>{product.title}</div>}
          {product && product.type === ProductType.Variable && (
            <div className={style.inventoryRowProductContentVariation}>
              {variation &&
                variation.attributeValues.map((a, i) => (
                  <span key={i} className={style.inventoryRowProductContentVariationAttribute}>
                    <b>{a.attribute.name}:</b>
                    <span>{a.value}</span>
                  </span>
                ))}
            </div>
          )}
        </div>
      </div>
      {(product && <IconSpec icon={<CubeTransparentIcon width={20} />} label="Tipo" content={product.type} />) || (
        <div></div>
      )}
      <IconSpec icon={<QrCodeIcon width={20} />} label="Barcode" content={barcode} />
      <IconSpec
        icon={<DocumentDuplicateIcon width={20} />}
        label="In magazzino"
        content={count.toString()}
        setData={(d) => {
          editCount(parseInt(d) - count);
        }}
        editableType="number"
        editable
      />
      <div className={style.inventoryRowButtons}>
        <div className={style.inventoryRowButtonsGroup}>
          <IconSpec
            icon={<PlusCircleIcon width={20} color={style.green} />}
            label=""
            labelStyle={{ color: style.green }}
            onClick={() => editCount(1)}
          />
          <IconSpec
            icon={<MinusCircleIcon width={20} color={style.green} />}
            label=""
            labelStyle={{ color: style.green }}
            onClick={() => editCount(-1)}
          />
        </div>
        {!product && (
          <IconSpec
            icon={<PlusCircleIcon width={20} color={style.green} />}
            label="Crea Task"
            labelStyle={{ color: style.green }}
            onClick={addTask}
          />
        )}
        <IconSpec
          icon={<TrashIcon width={20} color={style.red} />}
          label="Elimina"
          labelStyle={{ color: style.red }}
          onClick={deleteRowPrompt}
        />
      </div>
    </div>
  );
}
