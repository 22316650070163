import { ArrowUpIcon, ArrowDownIcon, LanguageIcon } from '@heroicons/react/24/outline';
import { ContentHeader, ContentHeaderGroup, ContentHeaderButton } from 'Components/ContentHeader';
import Loader from 'Components/Loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { GetBillItemsQuery, SearchBillItemsQuery } from 'GraphqlQueries';
import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';

import style from './billItems.module.scss';
import { toast } from 'react-toastify';
import BillItem from './billItem';
import { useSearchParams } from 'react-router-dom';

export default function Bills() {
  const per_page = 20;

  const [search, setSearch] = useState<string>('');
  const [sortBy, setSortBy] = useState<'itemCode' | 'description'>('description');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const [billFilter, setBillFilter] = useState<string | null>(urlSearchParams.get('bill'));
  const [isSemantic, setIsSemantic] = useState<boolean>(false);
  const isSearch = search !== '' || billFilter !== null;

  const { data, loading, error, fetchMore } = useQuery(isSearch ? SearchBillItemsQuery : GetBillItemsQuery, {
    variables: {
      input: {
        semantic: isSearch ? isSemantic : undefined,
        page: 1,
        per_page,
        sort_by: sortBy,
        sort_dir: sortDirection,
        q: isSearch ? search : undefined,
        query_by: isSearch ? ['description', 'itemCode'] : undefined,
        filter_by: isSearch && billFilter ? `$bill(id:${billFilter})` : undefined,
      },
    },
  });

  useEffect(() => {
    setBillFilter(urlSearchParams.get('bill'));
  }, [urlSearchParams]);

  useEffect(() => {
    if (error) {
      toast.error('Errore durante il caricamento dei prodotti: ' + error.message);
    }
  }, [error]);

  const nextPage = useCallback(
    (page: number) => {
      fetchMore({
        variables: {
          input: {
            semantic: isSearch ? false : undefined,
            page,
            per_page,
            sort_by: sortBy,
            sort_dir: sortDirection,
            q: isSearch ? search : undefined,
            query_by: isSearch ? ['description', 'itemCode'] : undefined,
            filter_by: isSearch && billFilter ? `$bill(id:${billFilter})` : undefined,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (fetchMoreResult.billItems.length === 0 || fetchMoreResult.billItems.length < per_page) {
            setHasMore(false);
          }

          if (!fetchMoreResult) return prev;

          return {
            ...prev,
            billItems: [...prev.billItems, ...fetchMoreResult.billItems],
          };
        },
      });

      setPage(page);
    },
    [billFilter, fetchMore, isSearch, search, sortBy, sortDirection],
  );

  return (
    <div className={style.content} id="scrollableContent">
      <InfiniteScroll
        dataLength={data?.billItems.length || 0}
        next={() => nextPage(page + 1)}
        hasMore={hasMore}
        loader={<Loader />}
        scrollableTarget="scrollableContent"
        style={{ minHeight: '100vh' }}
      >
        {loading && <Loader />}
        <ContentHeader
          title={'Dettagli fatture'}
          isSearchVisible
          searchText={search}
          onClickSearch={(s) => setSearch(s)}
          onDebounceSearch={(s) => setSearch(s)}
          filters={billFilter ? [{ label: 'Fattura', value: billFilter }] : undefined}
          onFilterRemove={() => {
            setBillFilter(null);
            setUrlSearchParams((params) => {
              params.delete('bill');
              return params;
            });
          }}
        >
          <ContentHeaderGroup label="Ordina per:">
            <ContentHeaderButton
              selected={sortBy === 'itemCode'}
              onClick={() => {
                setSortBy('itemCode');
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
              }}
              icon={sortDirection === 'asc' ? <ArrowUpIcon width={12} /> : <ArrowDownIcon width={12} />}
            >
              Codice Prodotto
            </ContentHeaderButton>
            <ContentHeaderButton
              selected={sortBy === 'description'}
              onClick={() => {
                setSortBy('description');
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
              }}
              icon={sortDirection === 'asc' ? <ArrowUpIcon width={12} /> : <ArrowDownIcon width={12} />}
            >
              Descrizione
            </ContentHeaderButton>
          </ContentHeaderGroup>

          <ContentHeaderGroup>
            <ContentHeaderButton
              selected={isSemantic}
              onClick={() => {
                setIsSemantic(!isSemantic);
              }}
              icon={<LanguageIcon width={12} />}
            >
              Ricerca semantica
            </ContentHeaderButton>
          </ContentHeaderGroup>
        </ContentHeader>

        <div className={style.contentBody}>
          {data?.billItems.map((billItem) => (
            <BillItem key={billItem.id} billItem={billItem} />
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
}
