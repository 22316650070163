import { useCallback, useEffect, useState } from 'react';
import style from './products.module.scss';
import { ContentHeader, ContentHeaderButton, ContentHeaderGroup } from 'Components/ContentHeader';
import { useQuery } from '@apollo/client';
import { GetProductsQuery, SearchProductsQuery } from 'GraphqlQueries';
import Loader from 'Components/Loader';
import Product from './product';
import { GQLProduct } from 'Types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/24/outline';
import { ServerUrl } from 'utils/casdoor';
import { useScannerReader } from 'Hooks';

export default function Products() {
  const [scannerData, setScannerData] = useScannerReader();

  const [stockFilter, setStockFilter] = useState<'all' | 'inStock' | 'outOfStock'>('all');
  const [sortBy, setSortBy] = useState<'price' | 'title' | 'createdAt'>('createdAt');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [filterBy, setFilterBy] = useState<string>('');

  const isSearch = search !== '' || stockFilter !== 'all' || filterBy !== '';
  const filters: { label: string; value: string }[] = [{ label: 'Barcode', value: scannerData.data }].filter(
    (f) => f.value,
  );

  const { data, loading, error, fetchMore, previousData } = useQuery(
    isSearch ? SearchProductsQuery : GetProductsQuery,
    {
      variables: {
        input: {
          page: 1,
          per_page: 10,
          semantic: isSearch ? false : undefined,
          query_by: isSearch ? ['title', 'description'] : undefined,
          sort_by: sortBy,
          sort_dir: sortDirection,
          filter_by: filterBy !== '' ? filterBy : undefined,
          q: search !== '' ? search : undefined,
        },
      },
    },
  );

  useEffect(() => {
    setFilterBy(
      [
        scannerData.data !== '' ? `$productVariation(sku:${scannerData.data})` : undefined,
        stockFilter !== 'all'
          ? `inStock:${stockFilter === 'inStock' ? 'true' : stockFilter === 'outOfStock' ? 'false' : ''}`
          : undefined,
      ]
        .filter((f) => f)
        .join(' '),
    );
  }, [scannerData, stockFilter]);

  useEffect(() => {
    if (error) {
      toast.error('Errore durante il caricamento dei prodotti: ' + error.message);
    }
  }, [error]);

  const nextPage = useCallback(
    (page: number) => {
      fetchMore({
        variables: {
          input: {
            page,
            per_page: 10,
            semantic: isSearch ? false : undefined,
            query_by: isSearch ? ['title', 'description'] : undefined,
            sort_by: sortBy,
            sort_dir: sortDirection,
            filter_by: filterBy !== '' ? filterBy : undefined,
            q: search !== '' ? search : undefined,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            ...prev,
            products: [...prev.products, ...fetchMoreResult.products],
          };
        },
      });

      setPage(page);
    },
    [fetchMore, filterBy, isSearch, search, sortBy, sortDirection],
  );

  const downloadCSV = useCallback(() => {
    fetch(`${ServerUrl}/api/v1/csv/export/products`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'products.csv');
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      });
  }, []);

  return (
    <div className={style.content} id="scrollableContent">
      <InfiniteScroll
        dataLength={data?.products.length || 0}
        next={() => nextPage(page + 1)}
        hasMore={(previousData?.products.length || 0) < (data?.products.length || 0)}
        loader={<Loader />}
        scrollableTarget="scrollableContent"
        style={{ minHeight: '100vh' }}
      >
        {loading && <Loader />}
        <ContentHeader
          title="Prodotti"
          isSearchVisible
          searchText={search}
          onClickSearch={(s) => setSearch(s)}
          onDebounceSearch={(s) => setSearch(s)}
          filters={filters}
          onFilterRemove={(value) => {
            setScannerData({ data: '' });
          }}
        >
          <ContentHeaderGroup label="Ordina per:">
            <ContentHeaderButton
              selected={sortBy === 'createdAt'}
              onClick={() => {
                setSortBy('createdAt');
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
              }}
              icon={sortDirection === 'asc' ? <ArrowUpIcon width={12} /> : <ArrowDownIcon width={12} />}
            >
              Data creazione
            </ContentHeaderButton>
            <ContentHeaderButton
              selected={sortBy === 'price'}
              onClick={() => {
                setSortBy('price');
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
              }}
              icon={sortDirection === 'asc' ? <ArrowUpIcon width={12} /> : <ArrowDownIcon width={12} />}
            >
              Prezzo
            </ContentHeaderButton>
            <ContentHeaderButton
              selected={sortBy === 'title'}
              onClick={() => {
                setSortBy('title');
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
              }}
              icon={sortDirection === 'asc' ? <ArrowUpIcon width={12} /> : <ArrowDownIcon width={12} />}
            >
              Titolo
            </ContentHeaderButton>
          </ContentHeaderGroup>

          <ContentHeaderGroup label="Filtri rapidi:">
            <ContentHeaderButton selected={stockFilter === 'all'} onClick={() => setStockFilter('all')}>
              Tutti
            </ContentHeaderButton>
            <ContentHeaderButton selected={stockFilter === 'inStock'} onClick={() => setStockFilter('inStock')}>
              In Stock
            </ContentHeaderButton>
            <ContentHeaderButton selected={stockFilter === 'outOfStock'} onClick={() => setStockFilter('outOfStock')}>
              Out of Stock
            </ContentHeaderButton>
          </ContentHeaderGroup>

          <ContentHeaderGroup label="">
            <ContentHeaderButton selected={false} onClick={() => downloadCSV()}>
              CSV
            </ContentHeaderButton>
          </ContentHeaderGroup>
        </ContentHeader>

        <div className={style.contentBody}>
          {data?.products.map((product: GQLProduct, i) => (
            <Product key={i} product={product} />
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
}
