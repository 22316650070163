import { BanknotesIcon, DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { Sidebar, SidebarMenu, SidebarMenuItem } from 'Components/Sidebar';

export function BillsSidebar(): JSX.Element {
  return (
    <Sidebar>
      <SidebarMenu>
        <SidebarMenuItem icon={<BanknotesIcon width={20} />} to="/bills/bills">
          Fatture
        </SidebarMenuItem>
        <SidebarMenuItem icon={<DocumentDuplicateIcon width={20} />} to="/bills/items">
          Articoli
        </SidebarMenuItem>
      </SidebarMenu>
    </Sidebar>
  );
}
