import {
  BuildingLibraryIcon,
  PercentBadgeIcon,
  QrCodeIcon,
  CurrencyEuroIcon,
  DocumentTextIcon,
  NumberedListIcon,
} from '@heroicons/react/24/outline';
import { IconSpec } from 'Components/IconSpec';
import style from './billItem.module.scss';
import { GQLBillItem } from 'Types';

export default function Bill({ billItem }: { billItem: GQLBillItem }) {
  return (
    <div className={style.bill}>
      <IconSpec icon={<QrCodeIcon width={20} />} label="Codice" content={billItem.itemCode} />
      <IconSpec icon={<DocumentTextIcon width={20} />} label="Descrizione" content={billItem.description} />
      <IconSpec icon={<BuildingLibraryIcon width={20} />} label="Fornitore" content={billItem.bill.supplier.name} />
      <IconSpec icon={<NumberedListIcon width={20} />} label="Quantità" content={billItem.quantity.toString()} />
      <IconSpec
        icon={<PercentBadgeIcon width={20} />}
        label="Sconto"
        content={billItem.discount ? `${billItem.discount}%` : 'Nessuno'}
      />
      <IconSpec
        icon={<CurrencyEuroIcon width={20} />}
        label="Prezzo unitario"
        content={`${(billItem.total / billItem.quantity).toFixed(2)} €`}
      />
    </div>
  );
}
