import { gql } from 'GQL';

export const GetBillsQuery = gql(`
  query GetBillsQuery($input: GraphQLListInput!) {
    bills: bills(input: $input) {
      ...BillFragment
    }
  }
`);

export const SearchBillsQuery = gql(`
  query SearchBills($input: GraphQLSearchInput!) {
    bills: searchBills(input: $input) {
      ...BillFragment
    }
  }
`);
