import { ArrowUpIcon, ArrowDownIcon, BanknotesIcon } from '@heroicons/react/24/outline';
import { ContentHeader, ContentHeaderGroup, ContentHeaderButton } from 'Components/ContentHeader';
import Loader from 'Components/Loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { GetBillsQuery, SearchBillsQuery } from 'GraphqlQueries';
import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useRef, useState } from 'react';

import style from './bills.module.scss';
import { toast } from 'react-toastify';
import { ServerUrl } from 'utils/casdoor';
import Bill from './bill';

export default function Bills() {
  const per_page = 20;

  const [search, setSearch] = useState<string>('');
  const [sortBy, setSortBy] = useState<'createdAt' | 'date'>('date');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [page, setPage] = useState<number>(1);
  const inputRef = useRef<HTMLInputElement>(null);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const { data, loading, error, fetchMore, refetch } = useQuery(search !== '' ? SearchBillsQuery : GetBillsQuery, {
    variables: {
      input: {
        semantic: search !== '' ? true : undefined,
        page: 1,
        per_page,
        sort_by: sortBy,
        sort_dir: sortDirection,
        q: search !== '' ? search : undefined,
        query_by: search !== '' ? ['documentNumber', 'supplierName'] : undefined,
      },
    },
  });

  useEffect(() => {
    if (error) {
      toast.error('Errore durante il caricamento dei prodotti: ' + error.message);
    }
  }, [error]);

  const nextPage = useCallback(
    (page: number) => {
      fetchMore({
        variables: {
          input: {
            semantic: search !== '' ? true : undefined,
            page,
            per_page,
            sort_by: sortBy,
            sort_dir: sortDirection,
            q: search !== '' ? search : undefined,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (fetchMoreResult.bills.length === 0 || fetchMoreResult.bills.length < per_page) {
            setHasMore(false);
          }

          if (!fetchMoreResult) return prev;
          return {
            ...prev,
            bills: [...prev.bills, ...fetchMoreResult.bills],
          };
        },
      });

      setPage(page);
    },
    [fetchMore, search, sortBy, sortDirection],
  );

  const uploadBill = useCallback(
    (xml: string) => {
      toast.info('Importazione della fattura in corso...');
      fetch(`${ServerUrl}/api/v1/bills/import`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ xml }),
      }).then((res) => {
        if (res.ok) {
          toast.success('Fattura importata con successo');
          refetch();
        } else {
          toast.error("Errore durante l'importazione della fattura");
        }
      });
    },
    [refetch],
  );

  return (
    <div className={style.content} id="scrollableContent">
      <InfiniteScroll
        dataLength={data?.bills.length || 0}
        next={() => nextPage(page + 1)}
        hasMore={hasMore}
        loader={<Loader />}
        scrollableTarget="scrollableContent"
        style={{ minHeight: '100vh' }}
      >
        {loading && <Loader />}
        <ContentHeader
          title={'Fatture'}
          isSearchVisible
          searchText={search}
          onClickSearch={(s) => setSearch(s)}
          onDebounceSearch={(s) => setSearch(s)}
        >
          <ContentHeaderGroup label="Ordina per:">
            <ContentHeaderButton
              selected={sortBy === 'createdAt'}
              onClick={() => {
                setSortBy('createdAt');
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
              }}
              icon={sortDirection === 'asc' ? <ArrowUpIcon width={12} /> : <ArrowDownIcon width={12} />}
            >
              Data creazione
            </ContentHeaderButton>
            <ContentHeaderButton
              selected={sortBy === 'date'}
              onClick={() => {
                setSortBy('date');
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
              }}
              icon={sortDirection === 'asc' ? <ArrowUpIcon width={12} /> : <ArrowDownIcon width={12} />}
            >
              Data emissione
            </ContentHeaderButton>
          </ContentHeaderGroup>

          <ContentHeaderGroup>
            <ContentHeaderButton
              selected={false}
              onClick={() => {
                if (inputRef.current) {
                  inputRef.current.click();
                }
              }}
              icon={<BanknotesIcon width={12} />}
            >
              Aggiungi nuova fattura
              <input
                type="file"
                style={{ display: 'none' }}
                ref={inputRef}
                accept=".xml"
                onClick={(e) => {
                  e.currentTarget.value = '';
                }}
                onChange={(e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    const file = e.target.files[0];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      if (e.target && e.target.result) {
                        uploadBill(e.target.result as string);
                      }
                    };
                    reader.readAsText(file);
                  }
                }}
              />
            </ContentHeaderButton>
          </ContentHeaderGroup>
        </ContentHeader>

        <div className={style.contentBody}>
          {data?.bills.map((bill: any) => (
            <Bill key={bill.id} bill={bill} refetch={refetch} />
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
}
