import { gql } from 'GQL';

export const GetBillItemsQuery = gql(`
  query GetBillItemsQuery($input: GraphQLListInput!) {
    billItems: billItems(input: $input) {
      ...BillItemFragment
    }
  }
`);

export const SearchBillItemsQuery = gql(`
  query SearchBillItems($input: GraphQLSearchInput!) {
    billItems: searchBillItems(input: $input) {
      ...BillItemFragment
    }
  }
`);
