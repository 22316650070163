import { ArrowDownIcon, ArrowUpIcon, CloudArrowUpIcon, DocumentPlusIcon } from '@heroicons/react/24/outline';
import { IconSpec } from 'Components/IconSpec';
import style from './inventory.module.scss';
import { Reference, useMutation, useQuery } from '@apollo/client';
import {
  DeleteInventoryMutation,
  GetInventoriesQuery,
  SearchInventoriesQuery,
  UpdateInventoryMutation,
  UpsertInventoriesMutation,
  UpsertInventoryMutation,
} from 'GraphqlQueries';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import Loader from 'Components/Loader';
import { GQLInventory, GQLProduct } from 'Types';
import ToastPrompt from 'Components/ToastPrompt';
import InventoryRow from './inventoryRow';
import { ContentHeader, ContentHeaderGroup, ContentHeaderButton } from 'Components/ContentHeader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { InventoryFragment } from 'GraphqlQueries/inventory.fragment';
import { useScannerReader } from 'Hooks';

export default function Inventory() {
  const [search, setSearch] = useState<string>('');
  const [sortBy, setSortBy] = useState<'updatedAt' | 'createdAt' | 'barcode'>('updatedAt');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [isRealTime, setIsRealTime] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [scanType, setScanType] = useState<'add' | 'remove'>('add');
  const inputRef = useRef<HTMLInputElement>(null);

  const [upsertInventory] = useMutation(UpsertInventoryMutation);
  const [upsertInventories] = useMutation(UpsertInventoriesMutation);
  const [deleteInventory] = useMutation(DeleteInventoryMutation);
  const [updateInventory] = useMutation(UpdateInventoryMutation);

  const [scannerData, setScannerData] = useScannerReader();

  const per_page = 20;

  const { data, loading, error, fetchMore, startPolling, stopPolling } = useQuery(
    search !== '' ? SearchInventoriesQuery : GetInventoriesQuery,
    {
      variables: {
        input: {
          page: 1,
          per_page,
          semantic: search !== '' ? false : undefined,
          query_by: search !== '' ? ['barcode'] : undefined,
          sort_by: sortBy,
          sort_dir: sortDirection,
          q: search !== '' ? search : undefined,
        },
      },
    },
  );

  const nextPage = useCallback(
    (page: number) => {
      fetchMore({
        variables: {
          input: {
            page,
            per_page,
            semantic: search !== '' ? false : undefined,
            query_by: search !== '' ? ['barcode'] : undefined,
            sort_by: sortBy,
            sort_dir: sortDirection,
            q: search !== '' ? search : undefined,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          if (fetchMoreResult.inventories.length === 0 || fetchMoreResult.inventories.length < per_page) {
            setHasMore(false);
          }
          return {
            ...prev,
            inventories: [...prev.inventories, ...fetchMoreResult.inventories],
          };
        },
      });

      setPage(page);
    },
    [fetchMore, search, sortBy, sortDirection],
  );

  const deleteRowPrompt = (barcode: string) => {
    const toastId = toast(
      <ToastPrompt
        onAbort={() => {
          toast.dismiss(toastId);
        }}
        onApply={() => {
          deleteInventory({
            variables: {
              id: parseInt(barcode),
            },
            update: (cache) => {
              cache.modify({
                fields: {
                  inventories(existingInventories = [], { readField }) {
                    return existingInventories.filter((iRef: Reference) => barcode !== readField('id', iRef));
                  },
                },
              });
            },
          });
          toast.dismiss(toastId);
        }}
        actionLabel="Elimina"
        textLabel={`Sicuro di voler eliminare definitivamente l'elemento?`}
      />,
      {
        style: {
          backgroundColor: 'white',
        },
      },
    );
  };

  useEffect(() => {
    if (error) {
      toast.error('Errore durante il caricamento dei prodotti: ' + error.message);
    }
  }, [error]);

  useEffect(() => {
    if (scannerData.data === '') return;

    upsertInventory({
      variables: {
        input: {
          barcode: scannerData.data,
          [scanType === 'add' ? 'addedCount' : 'removedCount']: 1,
        },
      },
      optimisticResponse(vars, { IGNORE }) {
        return {
          __typename: 'Mutation',
          inventory: {
            __typename: 'Inventory',
            id: '0',
            barcode: scannerData.data,
            count: 1,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            product: null,
          },
        };
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            inventories(existingInventories = [], { readField }) {
              const ref = existingInventories.find(
                (i: Reference) => readField('barcode', i) === data?.inventory.barcode,
              );
              const newCount =
                data?.inventory.id === '0'
                  ? ((readField('count', ref) as number) || 0) + (scanType === 'add' ? 1 : -1)
                  : (data?.inventory.count === undefined ? 0 : data?.inventory.count) || 0;
              const id = readField('id', ref);
              const product = readField('product', ref) as GQLProduct;

              const newInventoryRef = cache.writeFragment({
                data: {
                  __typename: 'Inventory',
                  barcode: data?.inventory.barcode || '',
                  createdAt: new Date().toISOString(),
                  updatedAt: new Date().toISOString(),
                  count: newCount,
                  id: id ? id.toString() : data?.inventory.id || '0',
                  product: product ? product : data?.inventory.product,
                },
                fragment: InventoryFragment,
              });

              // Remove duplicates
              return [newInventoryRef, ...existingInventories].filter(
                (inventoryRef, index, self) => index === self.findIndex((t) => t.__ref === inventoryRef.__ref),
              );
            },
          },
        });
      },
    }).then(() => {
      setScannerData({ data: '' });
    });
  }, [scanType, scannerData, setScannerData, upsertInventory]);

  useEffect(() => {
    if (isRealTime) {
      startPolling(1000);
    } else {
      stopPolling();
      setPage(1);
    }
  }, [isRealTime, startPolling, stopPolling]);

  const uploadInventory = useCallback(
    (csv: string) => {
      toast.info('Importazione inventario in corso...');
      const rows = csv.split('\n').filter((r) => r !== '');
      const input = rows
        .map((row) => {
          const [barcode, count] = row.split(',');
          return { barcode, count: parseInt(count) };
        })
        .filter((i) => !!i);

      upsertInventories({
        variables: {
          input,
        },
        update(cache, { data }) {
          cache.modify({
            fields: {
              inventories(existingInventories = []) {
                const newInventories =
                  data?.inventories
                    .map((i: GQLInventory) => {
                      return cache.writeFragment({
                        data: i,
                        fragment: InventoryFragment,
                      });
                    })
                    .filter((i) => !!i) || [];

                return [...newInventories, ...existingInventories].filter(
                  (inventoryRef, index, self) => index === self.findIndex((t) => t.__ref === inventoryRef.__ref),
                );
              },
            },
          });
        },
      }).then(() => {
        toast.success('Importazione inventario completata');
      });
    },
    [upsertInventories],
  );

  return (
    <div className={style.content} id="scrollableContent">
      <InfiniteScroll
        dataLength={data?.inventories.length || 0}
        next={() => {
          stopPolling();
          setIsRealTime(false);
          nextPage(page + 1);
        }}
        hasMore={hasMore}
        loader={<Loader />}
        scrollableTarget="scrollableContent"
        style={{ minHeight: '100vh' }}
      >
        <ContentHeader
          title="Inventario"
          isSearchVisible={true}
          onClickSearch={(s: string) => setSearch(s)}
          onDebounceSearch={(s: string) => setSearch(s)}
          searchText={search}
        >
          <ContentHeaderGroup label="Ordina per:">
            <ContentHeaderButton
              selected={sortBy === 'barcode'}
              onClick={() => {
                setSortBy('barcode');
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
              }}
              icon={sortDirection === 'asc' ? <ArrowUpIcon width={12} /> : <ArrowDownIcon width={12} />}
            >
              Barcode
            </ContentHeaderButton>
            <ContentHeaderButton
              selected={sortBy === 'createdAt'}
              onClick={() => {
                setSortBy('createdAt');
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
              }}
              icon={sortDirection === 'asc' ? <ArrowUpIcon width={12} /> : <ArrowDownIcon width={12} />}
            >
              Data Creazione
            </ContentHeaderButton>
            <ContentHeaderButton
              selected={sortBy === 'updatedAt'}
              onClick={() => {
                setSortBy('updatedAt');
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
              }}
              icon={sortDirection === 'asc' ? <ArrowUpIcon width={12} /> : <ArrowDownIcon width={12} />}
            >
              Data Modifica
            </ContentHeaderButton>
          </ContentHeaderGroup>
          <ContentHeaderGroup label="Scan type">
            <ContentHeaderButton selected={scanType === 'add'} onClick={() => setScanType('add')}>
              Aggiungi
            </ContentHeaderButton>
            <ContentHeaderButton selected={scanType === 'remove'} onClick={() => setScanType('remove')}>
              Rimuovi
            </ContentHeaderButton>
          </ContentHeaderGroup>
          <ContentHeaderGroup label="Tempo reale">
            <ContentHeaderButton selected={isRealTime} onClick={() => setIsRealTime(true)}>
              Tempo Reale
            </ContentHeaderButton>
            <ContentHeaderButton selected={!isRealTime} onClick={() => setIsRealTime(false)}>
              Stop
            </ContentHeaderButton>
          </ContentHeaderGroup>
          <ContentHeaderGroup>
            <ContentHeaderButton
              selected={false}
              onClick={() => {
                if (inputRef.current) {
                  inputRef.current.click();
                }
              }}
              icon={<DocumentPlusIcon width={12} />}
            >
              Importa inventario
              <input
                type="file"
                style={{ display: 'none' }}
                ref={inputRef}
                accept=".csv"
                onClick={(e) => {
                  e.currentTarget.value = '';
                }}
                onChange={(e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    const file = e.target.files[0];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      if (e.target && e.target.result) {
                        uploadInventory(e.target.result as string);
                      }
                    };
                    reader.readAsText(file);
                  }
                }}
              />
            </ContentHeaderButton>
          </ContentHeaderGroup>
        </ContentHeader>
        <div className={style.contentBody}>
          {loading && <Loader />}
          <div className={style.inventoryHeader}>
            <IconSpec
              icon={<CloudArrowUpIcon width={20} color={style.green} />}
              label="Applica inventario"
              labelStyle={{ color: style.green }}
              onClick={() => {
                console.log('applica inventario');
              }}
            />
            <IconSpec
              icon={<CloudArrowUpIcon width={20} color={style.red} />}
              label="Cancella tutto"
              labelStyle={{ color: style.red }}
              onClick={() => {
                console.log('cancella tutto');
              }}
            />
          </div>
          <div className={style.inventory}>
            {data?.inventories.map((inventory: GQLInventory, i: number) => (
              <InventoryRow
                key={i}
                inventory={inventory}
                deleteRowPrompt={() => deleteRowPrompt(inventory.id)}
                deleteRow={() => console.log('deleteRow')}
                editCount={(count) => {
                  updateInventory({
                    variables: {
                      id: parseInt(inventory.id),
                      input: {
                        count: inventory.count + count,
                      },
                    },
                  });
                }}
              />
            ))}
          </div>
        </div>
      </InfiniteScroll>
    </div>
  );
}
