import ToastPrompt from 'Components/ToastPrompt';
import { toast } from 'react-toastify';
import { useUpdateCheck } from 'react-update-notification';

export default function UpdateChecker() {
  const { status, reloadPage } = useUpdateCheck({
    type: 'interval',
    interval: 10000,
    ignoreServerCache: true,
  });

  if (status === 'checking' || status === 'current') {
    return null;
  }

  const showNotification = () => {
    const toastId = toast(
      <ToastPrompt
        actionLabel="Ricarica Pagina"
        textLabel={`Aggiornamento disponibile!`}
        onApply={function (): void {
          reloadPage();
          toast.dismiss(toastId);
        }}
        onAbort={function (): void {
          toast.dismiss(toastId);
        }}
      />,
      {
        style: {
          backgroundColor: 'white',
        },
        autoClose: false,
      },
    );
  };

  return <>{showNotification()}</>;
}
