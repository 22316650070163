import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/24/outline';
import { ContentHeader, ContentHeaderGroup, ContentHeaderButton } from 'Components/ContentHeader';
import Loader from 'Components/Loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { GQLAttributeValue } from 'Types';
import { SearchAttributeValuesQuery, GetAttributeValuesQuery } from 'GraphqlQueries';
import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';

import style from './attributeValues.module.scss';
import AttributeValue from './attributeValue';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { ServerUrl } from 'utils/casdoor';

export default function AttributeValues() {
  const [search, setSearch] = useState<string>('');
  const [sortBy, setSortBy] = useState<'createdAt' | 'value'>('value');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [page, setPage] = useState<number>(1);
  const { parentId, parentName } = useParams();

  const isSearch = true;

  const { data, loading, error, fetchMore, previousData } = useQuery(
    isSearch ? SearchAttributeValuesQuery : GetAttributeValuesQuery,
    {
      variables: {
        input: {
          page: 1,
          per_page: 50,
          semantic: isSearch ? false : undefined,
          query_by: isSearch ? ['value', 'slug'] : undefined,
          sort_by: sortBy,
          sort_dir: sortDirection,
          q: search !== '' ? search : undefined,
          filter_by: `$attribute(id:${parentId})`,
        },
      },
    },
  );

  useEffect(() => {
    if (error) {
      toast.error('Errore durante il caricamento dei prodotti: ' + error.message);
    }
  }, [error]);

  const nextPage = useCallback(
    (page: number) => {
      fetchMore({
        variables: {
          input: {
            page,
            per_page: 50,
            semantic: isSearch ? false : undefined,
            query_by: isSearch ? ['value', 'slug'] : undefined,
            sort_by: sortBy,
            sort_dir: sortDirection,
            q: search !== '' ? search : undefined,
            filter_by: `$attribute(id:${parentId})`,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            ...prev,
            products: [...prev.attributeValues, ...fetchMoreResult.attributeValues],
          };
        },
      });

      setPage(page);
    },
    [fetchMore, isSearch, parentId, search, sortBy, sortDirection],
  );

  const downloadCSV = useCallback(() => {
    fetch(`${ServerUrl}/api/v1/csv/export/attributeValues?attributeId=${parentId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'attributeValues.csv');
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      });
  }, [parentId]);

  const hasMore =
    page === 1 ||
    (data?.attributeValues && data?.attributeValues.length >= 50) ||
    previousData?.attributeValues.length === 50;
  return (
    <div className={style.content} id="scrollableContent">
      <InfiniteScroll
        dataLength={data?.attributeValues.length || 0}
        next={() => nextPage(page + 1)}
        hasMore={hasMore}
        loader={<Loader />}
        scrollableTarget="scrollableContent"
        style={{ minHeight: '100vh' }}
      >
        {loading && <Loader />}
        <ContentHeader
          title={parentName ? `Attributi > ${parentName}` : 'Attributi'}
          isSearchVisible
          searchText={search}
          onClickSearch={(s) => setSearch(s)}
          onDebounceSearch={(s) => setSearch(s)}
        >
          <ContentHeaderGroup label="Ordina per:">
            <ContentHeaderButton
              selected={sortBy === 'createdAt'}
              onClick={() => {
                setSortBy('createdAt');
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
              }}
              icon={sortDirection === 'asc' ? <ArrowUpIcon width={12} /> : <ArrowDownIcon width={12} />}
            >
              Data creazione
            </ContentHeaderButton>
            <ContentHeaderButton
              selected={sortBy === 'value'}
              onClick={() => {
                setSortBy('value');
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
              }}
              icon={sortDirection === 'asc' ? <ArrowUpIcon width={12} /> : <ArrowDownIcon width={12} />}
            >
              Valore
            </ContentHeaderButton>
          </ContentHeaderGroup>

          <ContentHeaderGroup label="">
            <ContentHeaderButton selected={false} onClick={() => downloadCSV()}>
              CSV
            </ContentHeaderButton>
          </ContentHeaderGroup>
        </ContentHeader>

        <div className={style.contentBody}>
          {data?.attributeValues.map((attribute: GQLAttributeValue, i) => (
            <AttributeValue key={i} attributeValue={attribute} />
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
}
