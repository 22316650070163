import { ContentHeader } from 'Components/ContentHeader';
import style from './index.module.scss';

import Scan from './scan';

export default function Scanner() {
  return (
    <div className={style.content}>
      <ContentHeader title="Nuova scansione" isSearchVisible={false}></ContentHeader>
      <div className={style.contentBody}>
        <Scan />
      </div>
    </div>
  );
}
