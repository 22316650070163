import { BanknotesIcon, CircleStackIcon, Cog6ToothIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { ViewModule } from 'Types';
import style from './AppShell.module.scss';
import { ToastContainer } from 'react-toastify';
import { useSerialPort } from 'Hooks/useSerialPort';
import { AppContext } from './context';
import UpdateChecker from 'Components/UpdateChecker';

function Icon({
  children,
  onClick,
  selected,
}: {
  children: JSX.Element;
  onClick: () => void;
  selected: boolean;
}): JSX.Element {
  if (selected) {
    return (
      <div className={style.iconSelected}>
        <div className={style.iconSelectedBefore}></div>
        <div className={style.iconSelectedContent}>{children}</div>
        <div className={style.iconSelectedAfter}></div>
      </div>
    );
  } else {
    return (
      <div className={style.icon} onClick={onClick}>
        {children}
      </div>
    );
  }
}

export default function AppShell({
  content,
  sidebar,
  module,
}: {
  content: JSX.Element;
  sidebar: JSX.Element;
  module: ViewModule;
}): JSX.Element | null {
  const { requestUserAction, useSerialData, ports } = useSerialPort();
  const navigate = useNavigate();

  return (
    <AppContext.Provider value={{ requestUserAction, useSerialData, ports }}>
      <div className={style.appShell}>
        <div className={style.sideBar}>
          <div className={style.sideBarParent}>
            <Icon onClick={() => navigate('/products')} selected={module === ViewModule.WareHouse}>
              <CircleStackIcon width={24} />
            </Icon>
            <Icon onClick={() => navigate('/bills')} selected={module === ViewModule.Bills}>
              <BanknotesIcon width={24} />
            </Icon>
            <Icon onClick={() => navigate('/settings')} selected={module === ViewModule.Settings}>
              <Cog6ToothIcon width={24} />
            </Icon>
          </div>
          {sidebar}
        </div>
        <div className={style.content}>{content}</div>
        <ToastContainer position="bottom-right" theme="colored" />
        <UpdateChecker />
      </div>
    </AppContext.Provider>
  );
}
