import { useEffect, useState } from 'react';

const emitter = new EventTarget();

function reader() {
  let code = '';
  let reading = false;

  document.addEventListener('keypress', (e) => {
    if ((e.target as Element)?.localName === 'input') {
      return;
    }

    //usually scanners throw an 'Enter' key at the end of read
    if (e.key === 'Enter') {
      emitter.dispatchEvent(new CustomEvent('data', { detail: code }));
      code = '';
    } else {
      code += e.key; //while this is not an 'enter' it stores the every key
    }

    //run a timeout of 200ms at the first read and clear everything
    if (!reading) {
      reading = true;
      setTimeout(() => {
        code = '';
        reading = false;
      }, 200); //200 works fine for me but you can adjust it
    }
  });
}

export function useScannerReader(): [{ data: string }, React.Dispatch<React.SetStateAction<{ data: string }>>] {
  const [data, setData] = useState<{ data: string }>({ data: '' });

  const callback = (event: Event) => {
    const customEvent = event as CustomEvent<string>;
    setData({ data: customEvent.detail });
  };

  useEffect(() => {
    emitter.addEventListener('data', callback);
    return () => {
      emitter.removeEventListener('data', callback);
    };
  }, []);

  return [data, setData];
}

reader();
